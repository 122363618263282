import { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logoImage from '../../assets/login.png';

const ChatHeader = ({ user }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const onLogoutClick = () => {
    localStorage.removeItem('userToken');
    navigate('/');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <>
      <header className="sticky top-0 bg-[#111827] dark:bg-[#182235] border-b border-slate-200 dark:border-slate-700 z-30">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center h-16 -mb-px">
            {/* Logo Section Header */}
            
            {/* Logo */}
            <NavLink end to="/" className="flex flex-grow">
              <img
                src={logoImage}
                alt=""
                className="h-auto w-40  px-3 py-2  rounded"
              />
            </NavLink>

            {/* Header: Right side */}
            <div className="relative flex items-center space-x-3">
              <div className="relative">
                <button
                  type="button"
                  className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0"
                  id="user-menu-button"
                  aria-expanded={dropdownOpen}
                  onClick={toggleDropdown}
                >
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="w-8 h-8 rounded-full"
                    src={user.picture}
                    alt="user photo"
                  />
                </button>
                {dropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-lg"
                    id="user-dropdown"
                  >
                    <div className="px-4 py-3">
                      <span className="block text-sm text-gray-900">
                        {user.name}
                      </span>
                      <span className="block text-sm text-gray-500 truncate">
                        {user.email}
                      </span>
                    </div>
                    <ul className="py-2" aria-labelledby="user-menu-button">
                      <li>
                        <button
                          onClick={onLogoutClick}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                        >
                          Sign out
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              {/*  Divider */}
              <hr className="w-px h-6 bg-slate-200 dark:bg-slate-700 border-none" />
              {/* <UserMenu align="right" /> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default ChatHeader;
