import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Message, Reply } from './ChatElements';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const DisplayMessage = ({
  handleToggleShowPrompts,
  recentSearches,
  responseData,
  showPrompts,
  promptMessages,
  handlePromptClick,
  selectedPrompt,
}) => {
  const messagesEndRef = useRef(null);
  const [user, setUser] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token === null) {
      navigate('/');
    } else {
      var userObject = jwt_decode(token);
      setUser(userObject);
    }
    scrollToBottom();
  }, [recentSearches]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  return (
    <div className="flex-grow bg-white overflow-auto w-full custom-scrollbar">
      <div className="cursor-pointer w-5/6 m-auto">
        <button
          onClick={handleToggleShowPrompts}
          className="px-4 py-1 m-3 text-base 
          bg-indigo-100 text-gray-500 rounded-full 
          border hover:text-white hover:bg-indigo-500 
          focus:outline-none focus:ring-offset-2"
        >
          {showPrompts ? (
            <>
              Try Asking About
              <FontAwesomeIcon icon={faAngleUp} className="ml-2" />
            </>
          ) : (
            <>
              Try Asking About
              <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
            </>
          )}
        </button>
      </div>
      <div className="m-auto">
        {showPrompts && (
          <div className="w-5/6 m-auto">
            {
            promptMessages.map((prompt, index) => (
              <button
                onClick={() => handlePromptClick(prompt)}
                key={index}
                className="px-4 py-1 m-3 text-base cursor-pointer 
                bg-indigo-100 text-gray-500 rounded-full border
                border-purple-200 hover:text-white hover:bg-indigo-500 
                hover:border-transparent focus:outline-none focus:ring-2
                focus:bg-indigo-500"
              >
                {prompt.message}
              </button>
            ))
            }
          </div>
        )}
        {recentSearches.map((search, index) => (
          <div key={index} className="bg-white rounded-lg">
            <Message text={search.input} image={user.picture} />
            {search.output ? (
              <Reply
                text={search.output}
                input={search.input}
                isVisible={index === recentSearches.length - 1}
              />
            ) : null}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default DisplayMessage;
