import { useState } from 'react';

const usePostQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [error, setError] = useState(null);

  const submitData = async (url, requestData) => {
    setIsLoading(true);

    if(requestData.question === "") {
      throw new Error('Enter the question');
    }
    
    var raw = JSON.stringify(requestData);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: raw,
        redirect: 'follow'
      });
  
      if (!response.ok) {
        throw new Error('Request failed');
      }
  
      const data = await response.json();
      setResponseData(data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  

  return { isLoading, responseData, error, submitData };
};

export default usePostQuery;
