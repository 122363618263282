import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePostQuery from "../../Hooks/usePostQuery";
import ChatHeader from "./ChatHeader";
import DisplayMessage from "./DisplayMessage";
import Input from "./Input";
import Conversations from "./Conversations";

const Chat = () => {
  const promptMessages = [
    {
      message: 'Email campaign ideas',
      info: 'Generate 3 email campaign ideas for this month for WeCommerce, an omnichannel company.',
    },
    {
      message: 'Summer deals social posts',
      info: 'Generate 3 options of Facebook and Instagram posts for Summer deals for WeCommerce, an omnichannel company.',
    },
    {
      message: 'Independence day deals',
      info: 'Create 3 catchy email subject options for promoting Independence day sales on WeCommerce',
    },
    {
      message: 'Responsible living blog ideas',
      info: 'Generate 5 blog ideas for WeCommerce, an omnichannel company to promote responsible living.',
    },
    {
      message: 'Blog Article',
      info: 'The Rise of Ethical Consumerism: Why Your Shopping Choices Matter',
    },
  ];

  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [recentSearches, setRecentSearches] = useState([]);
  const [showPrompts, setShowPrompts] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const { responseData, isLoading, submitData } = usePostQuery();

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token === null) {
      navigate("/");
    } else {
      var payload = JSON.parse(token);
      const currentDate = new Date();
      if (currentDate > payload.expirationTime) {
        console.log("Token has expired");
        localStorage.removeItem("userToken");
        navigate("/");
      }

      var userObject = jwt_decode(payload.token);
      setUser(userObject);
    }
    if (JSON.stringify(responseData) !== "{}" && !isLoading) {
      setRecentSearches((prev) => {
        const updatedSearches = [...prev];
        updatedSearches[prev.length - 1].output = responseData.answer || "";
        return updatedSearches;
      });
    }
  }, [responseData, isLoading]);

  const handleSearch = async () => {
    setShowPrompts(false);
    if (searchValue.trim() === "") return;
    setRecentSearches((prev) => [...prev, { input: searchValue, output: "" }]);
    setSearchValue("");

    try {
      await submitData("https://c360-backend-api.aedeon.com/query", {
        question: searchValue,
      });

      setShowPrompts(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePromptClick = async (prompt) => {
    setShowPrompts(false);
    setRecentSearches((prev) => [...prev, { input: prompt.info, output: '' }]);
    try {
      await submitData('https://c360-backend-api.aedeon.com/query', {
        question: prompt.info,
      });
      setSearchValue('');
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleShowPrompts = () => {
    setShowPrompts(!showPrompts);
  };

  const handleChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  return (
    <>
      {/* Page Content */}
      <div className="flex flex-col overflow-y-hidden h-screen">
        {/* <Navbar /> */}
        {user && <ChatHeader user={user} />}

        <div className="flex h-full overflow-y-hidden">
          {/** Aside */}
          <Conversations recentSearches={promptMessages} />

          {/* Message Display and Input */}
          <div className="flex flex-col h-full w-full m-auto items-center justify-center">
            {/* Prompt Messages */}
            <DisplayMessage
              handleToggleShowPrompts={handleToggleShowPrompts}
              showPrompts={showPrompts}
              promptMessages={promptMessages}
              handlePromptClick={handlePromptClick}
              selectedPrompt={selectedPrompt}
              responseData={responseData}
              recentSearches={recentSearches}
            />

            {/* Input */}
            <Input
              handleChange={handleChange}
              searchValue={searchValue}
              handleSearch={handleSearch}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Chat;
