import { useEffect, React } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Input = ({ handleSearch, handleChange, searchValue }) => {
  useEffect(() => {
    // Setup enter as send in the textarea
    const myTextArea = document.getElementById('messageBox');
    const myButton = document.getElementById('messageSend');
    myTextArea.addEventListener('keydown', function (event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevent line break in the textarea
        myButton.click(); // Submit the form
      }
    });
  }, [])
  
  return (
    <div className="bg-white p-4 pt-0 w-full">
      <div className="relative flex w-5/6 mx-auto">
          <textarea
            id="messageBox"
            value={searchValue}
            onChange={(e) => handleChange(e)}
            placeholder="Type your message..."
            className="resize-none overflow-hidden w-full px-8 py-3 pr-12 rounded-full 
            text-gray-500 h-12 bg-gray-100 text-base border border-gray-200 
            placeholder-gray-500 focus:outline-none focus:border-gray-400 
            focus:bg-white search-input"
          >
          </textarea>
          <button
            className="absolute cursor-pointer top-0 right-0 px-4 h-12 py-2 
            text-white focus:outline-none font-semibold  bg-indigo-400 
            hover:bg-indigo-500 transition-all duration-300 ease-in-out flex 
            items-center focus:shadow-outline rounded-full"
          onClick={handleSearch}
          id="messageSend"
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default Input;
