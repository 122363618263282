import React, { useState, useRef, useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Applications from './Applications';
import jwt_decode from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';



function Dashboard(props) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState(); 
  const trigger = useRef(null);

  // navigate
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token === null) {  
      navigate("/")
    } else {
      var payload = JSON.parse(token);
      const currentDate = new Date();
      if (currentDate > payload.expirationTime) {
        console.log("Token has expired")
        localStorage.removeItem("userToken")
        navigate("/")
      }

      var userObject = jwt_decode(payload.token);
      console.log(userObject)
      setUser(userObject);
    }
  }, [])

  return (
    <>
      { user && <div className="flex flex-col h-screen overflow-y-hidden">
          {/*  Site header */}

          <Header 
            sidebarOpen={sidebarOpen} 
            setSidebarOpen={setSidebarOpen} 
            userData={user}
            trigger={trigger}
          />

          {/* Content area */}
          <main className="flex h-full w-full">
            {/* Sidebar */}
            <Sidebar 
              sidebarOpen={sidebarOpen} 
              setSidebarOpen={setSidebarOpen}
              trigger={trigger}
            />
            <div className="w-full px-4 mb-16 flex justify-center overflow-y-auto custom-scrollbar">
              <Applications />
            </div>
          </main>
        </div>
      }
    </>
    
  );
}

export default Dashboard;