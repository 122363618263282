const SearchCard = ({ text }) => {
    return (
      <div className="flex flex-row rounded-md p-2 my-2 items-center border-2">
        <div className="w-1/6">
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-4 w-4"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        </div>
        <div className="w-5/6">
          <div className="text truncate">{text}</div>
        </div>
      </div>
    );
  };
  
const Conversations = ({ recentSearches }) => {
    const questions = [
      {
        question: "Generate a 3 email marketing campaign for the best customers at WeCommerce",
      },
      {
        question: "Create 5 options for the social post to promote the deal of the week at WeCommerce, an omnichannel company.",
      },
      {
        question: "Generate a 3 email campaign for customers who are likely to churn this month at WeCommerce",
      },
      {
        question:
          "Rewrite the blog 7 Sustainable Fashion Tips to Make a Difference",
      },
      {
        question: `Rewrite the blog "The Importance of Ethical Sourcing: How WeCommerce Supports Fair Trade"`,
      },
      {
        question:
          `Rewrite the blog "Reducing Waste: How WeCommerce Empowers customers to Shop Responsibly"`,
      },
      {
        question: `Write the blog "Choosing Natural Products: Our Top Picks for Organic and Eco-Friendly Brands"`,
      },
      {
        question:
          `Write the blog "Green Living Made Easy: How WeCommerce Promotes Sustainable Choices for a Better Environment"`,
      },
    ];
    return (
      <>
        {/* Recent Searches */}
        <aside
          id="default-sidebar"
          className="flex flex-col w-1/4 border-r-2 overflow-y-auto custom-scrollbar"
          aria-label="Sidebar"
        >
          <div className="p-4 bg-white">
            <h2 className="text-gray-500 text-sm font-semibold mb-2">Recommended Questions</h2>
            {questions.map((question, index) => (
              <SearchCard text={question.question} key={`hem-${index}`}/>
            ))}
          </div>
        </aside>
      </>
    );
};

export default Conversations;
