import s3Image from '../../assets/apps/s3.png';
import airflowImage from '../../assets/apps/airflowlogo.png';
import athenaImage from '../../assets/apps/athenalogo.png';
import quicksightImage from '../../assets/apps/quicksightlogo.png';
import redshiftImage from '../../assets/apps/redshiftlogo.png';
import trinoImage from '../../assets/apps/trinologo.png';

import gaImage from '../../assets/datasets/googleanalyticslogo.png';
import hjImage from '../../assets/datasets/hotjarlogo.png';
import hubspotImage from '../../assets/datasets/hubspotlogo.png';
import marketoImage from '../../assets/datasets/marketologo.png';
import mpImage from '../../assets/datasets/mixpanellogo.png';
import sfImage from '../../assets/datasets/salesforcelogo.webp';
import c360Image from '../../assets/logo-m.png';
import ApplicationCard from './ApplicationCard'


const Applications = (props) => {
  const datasets = [
    { id:"1", title: 'Google Analytics', image: gaImage, link: '/chat' },
    { id:"2", title: 'Hotjar', image: hjImage, link: 'https://insights.hotjar.com/sites/2327305/overview' },
    { id:"3", title: 'HubSpot', image: hubspotImage, link: 'https://app.hubspot.com/seo-analytics-tools-ui/2205669' },
    { id:"4",title: 'Marketo', image: marketoImage, link: '/chat' },
    { id:"5",title: 'Mixpanel', image: mpImage, link: 'https://mixpanel.com/project/2131808/view/284637/app/boards#id=4205139' },
    { id:"6",title: 'Salesforce', image: sfImage, link: '/chat' },
  ];

  const apps = [
    { id:"7",title: 'Airflow', image: airflowImage, link: '/chat' },
    { id:"8",title: 'Athena', image: athenaImage, link: '/chat' },
    { id:"9",title: 'Quicksight', image: quicksightImage, link: 'https://us-east-1.quicksight.aws.amazon.com/sn/start/analyses' },
    { id:"10",title: 'Redshift', image: redshiftImage, link: '/chat' },
    { id:"11",title: 'Trino', image: trinoImage, link: '/chat' },
    { id:"12",title: 'S3', image: s3Image, link: '/chat' },
    { id:"13",title: 'C360GPT', image: c360Image, link: '/chat' },
  ];

  return (
    <>
      <div className="flext justify-center h-full">
        <div>
          <p className="text-gray-700 text-2xl text-start font-medium ml-1 mt-4 pb-0">
            Datasets
          </p>
          <div className="grid grid-cols-6 gap-3 mt-3">
            {datasets.map((dataset,index) => (
              <ApplicationCard
                index={dataset.id}
                title={dataset.title}
                image={dataset.image}
                link={dataset.link}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col mb-40">
          <h4 className="text-gray-700 text-2xl text-start font-medium mt-4 ml-1 pb-0">
            Applications
          </h4>
          <div className="grid grid-cols-6 gap-3 mt-4">
            {apps.map((app, index) => (
              <ApplicationCard
                index={app.id}
                title={app.title}
                image={app.image}
                link={app.link}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Applications;
