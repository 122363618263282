import { Link } from 'react-router-dom';
const ApplicationCard = (props) => {
  return (
    <div key={props.index} className="h-40 w-40 bg-white border border-gray-200 rounded shadow">
      <Link
        to={props.link}
        target="_blank"
        className="flex flex-col items-center justify-center p-3 cursor-pointer"
      >
        <div className="flex items-center justify-center h-28">
          <img
            className="object-contain max-h-full rounded"
            src={props.image}
            alt="Bonnie image"
          />
        </div>
        <h5 className="mb-2 mt-1 text-md font-medium text-gray-500 text-center">
          {props.title}
        </h5>
      </Link>
    </div>
  );
};

export default ApplicationCard;