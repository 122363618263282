import { useState, useRef, useEffect } from "react";

export const Message = ({ image, text }) => {
    return (
      <>
        <div className="flex w-full bg-indigo-100 p-2">
            <div className="flex w-5/6 m-auto">
                <p className="whitespace-pre-wrap container px-8 text-gray-500 p-3">
                    {text}
                </p>
                <img src={image} alt="" className="w-8 h-8 rounded-full m-2" />
            </div>
        </div>
      </>
    );
};
  
export const Reply = ({ text, input, isVisible }) => {
    const [typedText, setTypedText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isGenerating, setIsGenerating] = useState(true);
    const [copied, setCopied] = useState(false);
    const textRef = useRef(null);
  
    useEffect(() => {
      let typingInterval;
  
      const startTyping = () => {
        typingInterval = setInterval(() => {
          setCurrentIndex((prevIndex) => {
            const nextIndex = prevIndex + 1;
            const currentText = text.slice(0, nextIndex);
            setTypedText(currentText);
            if (nextIndex === text.length) {
              setIsGenerating(false);
              clearInterval(typingInterval);
            }
            return nextIndex;
          });
  
          // Scroll to the newly typed letter
          if (textRef.current) {
            textRef.current.scrollIntoView({ behavior: "auto" });
          }
        }, 25);
      };
  
      if (isGenerating && currentIndex < text.length) {
        startTyping();
      }
  
      return () => {
        clearInterval(typingInterval);
      };
    }, [text, isGenerating, currentIndex]);
  
    const handleStopGenerating = () => {
      setIsGenerating(false);
    };
  
    const handleContinueGeneration = () => {
      setIsGenerating(true);
      setCopied(false);
    };
  
    const handleCopyToClipboard = () => {
      navigator.clipboard.writeText(typedText);
      setCopied(true);
    };
  
    const isGenerationComplete = currentIndex === text.length;
    const isTextPending =
      !isGenerationComplete || (isGenerationComplete && isGenerating);
  
    return (
      <>
        <div className="flex flex-col w-full bg-gray-100 p-2">
          <div className="relative w-5/6 m-auto">
            <p
              ref={textRef}
              className="whitespace-pre-wrap p-3 px-8 container text-gray-500 w-full"
            >
              {typedText}
            </p>
          </div>
        </div>
        {isTextPending && isVisible && (
          <div className="flex justify-center">
            {isGenerating ? (
              <button
                ref={textRef}
                className="my-2 cursor-pointer px-4 py-2 text-gray-500 bg-indigo-100 hover:bg-indigo-400 hover:text-white rounded"
                onClick={handleStopGenerating}
              >
                Stop Generating
              </button>
            ) : (
              <button
                ref={textRef}
                className="my-2 cursor-pointer px-4 py-2 text-gray-500 bg-indigo-100 hover:bg-indigo-400 hover:text-white rounded"
                onClick={handleContinueGeneration}
              >
                Continue Generation
              </button>
            )}
          </div>
        )}
        {isGenerationComplete && isVisible && (
          <div className="flex justify-center">
            <button
              ref={textRef}
              className="my-2 cursor-pointer px-4 py-2 text-gray-500 bg-indigo-100 hover:bg-indigo-400 hover:text-white rounded"
              onClick={handleCopyToClipboard}
            >
              {copied ? "Copied!" : "Copy to Clipboard"}
            </button>
          </div>
        )}
      </>
    );
};